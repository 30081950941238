// src/context/GameContext.jsx
import React, { createContext, useState, useEffect, useRef } from 'react';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [lives, setLives] = useState(3);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isGameRunning, setIsGameRunning] = useState(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (isGameRunning) {
      // Start the timer
      timerRef.current = setInterval(() => {
        setElapsedTime((prev) => prev + 0.1);
      }, 100);
    } else {
      // Stop the timer
      clearInterval(timerRef.current);
    }

    // Cleanup if the component unmounts
    return () => clearInterval(timerRef.current);
  }, [isGameRunning]);

  const resetGame = () => {
    // Reset the time to 0, and ensure the timer is not running.
    setElapsedTime(0);
    setIsGameRunning(false);
    // If you also want to reset lives, you can do that here or in your game flow.
  };

  return (
    <GameContext.Provider
      value={{
        difficulty,
        setDifficulty,
        lives,
        setLives,
        elapsedTime,
        setElapsedTime,
        isGameRunning,
        setIsGameRunning,
        resetGame,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
