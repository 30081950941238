// src/pages/HomePage.jsx
import React, { useContext, useEffect } from 'react';
import { GameContext } from '../context/GameContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const HomePage = ({ onStart }) => {
  const { difficulty, setDifficulty } = useContext(GameContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  // (B) Radar Targets: 
  // Use an effect that spawns a "target" circle every 2.2s, 
  // removes it after 1s.
  useEffect(() => {
    const radarContainer = document.querySelector('.radar-background');
    if (!radarContainer) return;

    const spawnTarget = () => {
      // Create a div for the target
      const target = document.createElement('div');
      target.className = 'radar-target';

      // Position it randomly inside radarContainer
      const containerWidth = radarContainer.offsetWidth;
      const containerHeight = radarContainer.offsetHeight;
      const x = Math.random() * containerWidth;
      const y = Math.random() * containerHeight;

      target.style.left = `${x}px`;
      target.style.top = `${y}px`;

      radarContainer.appendChild(target);

      // Remove after 1s
      setTimeout(() => {
        if (target.parentNode) {
          target.parentNode.removeChild(target);
        }
      }, 1000);
    };

    // Spawn a target every 2.2s
    const intervalId = setInterval(spawnTarget, 2200);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleStartGame = () => {
    onStart && onStart();
  };

  return (
    <div className="home-page">
      {/* The new radar background div */}
      <div className="radar-background"></div>

      <header className="home-header">
        <div className="language-selector">
          <select onChange={handleLanguageChange}>
            <option value="en">EN</option>
            <option value="de">DE</option>
          </select>
        </div>
      </header>

      <main className="home-content">
        <h1>{t('welcome')}</h1>

        <div className="difficulty-buttons">
          <button
            className={difficulty === 'easy' ? 'selected' : ''}
            onClick={() => setDifficulty('easy')}
          >
            Visual
          </button>
          
          <button
            className={difficulty === 'hard' ? 'selected' : ''}
            onClick={() => setDifficulty('hard')}
          >
            Reddit-style
          </button>
        </div>

        <button
          className="start-game-btn"
          onClick={handleStartGame}
        >
          {t('startGame')}
        </button>

        <div className="nav-buttons">
        <button onClick={() => navigate('/rules')}>
          {t('rules')}
        </button>
        <button onClick={() => navigate('/leaderboard')}>
          {t('leaderboard')}
        </button>
        </div>
      </main>
      <footer className="home-footer">
        <a href={t('footerLinkUrl')} target="_blank" rel="noopener noreferrer">
          {t('footerLinkText')}
        </a>
      </footer>
    </div>
  );
};

export default HomePage;