// src/components/GameOverScreen.jsx
import React, { useContext, useState } from 'react';
import { GameContext } from '../context/GameContext';
import { useTranslation } from 'react-i18next';

const GameOverScreen = ({ onRestart, answered = 0, total = 0 }) => {
  const { elapsedTime, resetGame } = useContext(GameContext);
  const { t } = useTranslation();

  // Short-lived notification logic
  const [notification, setNotification] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const showTempNotification = (msgKey) => {
    setNotification(t(msgKey));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handleRestart = () => {
    resetGame();
    onRestart && onRestart();
  };

  const handleChallenge = async () => {
    const shareLink = `${window.location.origin}?score=${elapsedTime.toFixed(1)}`;
    try {
      await navigator.clipboard.writeText(
        `My score is ${elapsedTime.toFixed(1)}s, challenge me! ${shareLink}`
      );
      showTempNotification('challengeCopied');
    } catch (error) {
      console.error('Clipboard error:', error);
      alert(t('clipboardError'));
    }
  };

  // 1) Calculate ratio
  const ratio = total > 0 ? answered / total : 0;

  // 2) Decide which localized text to show
  let ratioMessageKey = 'scoreBelow30'; // fallback if ratio < 0.3
  if (ratio > 0.9) {
    ratioMessageKey = 'score91_100';
  } else if (ratio > 0.7) {
    ratioMessageKey = 'score71_90';
  } else if (ratio > 0.3) {
    ratioMessageKey = 'score31_70';
  }

  return (
    <div className="game-over-screen">
      <h2>{t('gameOverTitle')}</h2>
      <p>{t('yourTime')}: {elapsedTime.toFixed(1)}s</p>

      {/* Show how many answered out of total */}
      <p>
        {t('questionsAnswered', { answered, total })}
      </p>

      {/* Show ratio-based message */}
      <p>{t(ratioMessageKey)}</p>

      {showNotification && (
        <div className="notification">
          {notification}
        </div>
      )}

      <div className="actions">
        <button onClick={handleRestart}>{t('restart')}</button>
        <button onClick={handleChallenge}>{t('challengeFriend')}</button>
      </div>
    </div>
  );
};

export default GameOverScreen;
