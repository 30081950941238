// src/components/GameType1.jsx
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// Simple utility function to shuffle an array
function shuffleArray(array) {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
}

const GameType1 = ({ question, onCorrect, onIncorrect }) => {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [feedbackColor, setFeedbackColor] = useState(null);

  // Shuffle question.options whenever the question changes
  useEffect(() => {
    if (question?.options) {
      setShuffledImages(shuffleArray(question.options));
    }
  }, [question]);

  const handleImageClick = (imgId) => {
    setSelectedImage(imgId);
    if (imgId === question.correctId) {
      setFeedbackColor('green');
      setTimeout(() => {
        setFeedbackColor(null);
        setSelectedImage(null);
        onCorrect();
      }, 1000);
    } else {
      setFeedbackColor('red');
      setTimeout(() => {
        setFeedbackColor(null);
        setSelectedImage(null);
        onIncorrect();
      }, 1000);
    }
  };

  return (
    <div className="game-type-1">
      <h2>{question.title}</h2>
      <div className="images-container">
        {shuffledImages.map((img) => (
          <img
            key={img.id}
            src={img.src}
            alt={img.alt}
            className={classNames('option-img', {
              'highlight-green': selectedImage === img.id && feedbackColor === 'green',
              'highlight-red': selectedImage === img.id && feedbackColor === 'red',
            })}
            onClick={() => handleImageClick(img.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default GameType1;
