// src/components/FinalScreen.jsx
import React, { useState, useContext, useEffect } from 'react';
import { GameContext } from '../context/GameContext';
import { db } from '../services/firebase';
import { collection, addDoc, getDocs, orderBy, query, limit } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const FinalScreen = ({ onRestart }) => {
  const { elapsedTime, resetGame } = useContext(GameContext);
  const [playerName, setPlayerName] = useState('');
  const [top3, setTop3] = useState([]);
  const [notification, setNotification] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); // Prevent multiple submissions
  const [userInTop3, setUserInTop3] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchTop3();
    // eslint-disable-next-line
  }, []);

  const fetchTop3 = async () => {
    try {
      const q = query(collection(db, 'leaderboard'), orderBy('time', 'asc'), limit(3));
      const snapshot = await getDocs(q);
      const results = snapshot.docs.map((doc) => doc.data());
      setTop3(results);
    } catch (error) {
      console.error('Error fetching top3:', error);
      showTempNotification('errorFetchingTop3');
    }
  };

  const showTempNotification = (msgKey) => {
    setNotification(t(msgKey));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };
  
  const nameRegex = /^[A-Za-z0-9_ ]{1,20}$/;

  const handleSubmitToLeaderboard = async () => {
    if (!playerName) {
      showTempNotification('enterNameWarning');
      return;
    }
    if (hasSubmitted) {
      showTempNotification('alreadySubmitted');
      return;
    }
    if (!nameRegex.test(playerName)) {
      alert('Invalid name. Up to 20 alphanumeric, underscores, or spaces only.');
      return;
    }

    try {
      await addDoc(collection(db, 'leaderboard'), {
        name: playerName,
        time: parseFloat(elapsedTime.toFixed(1)),
      });
      showTempNotification('scoreSubmitted');
      setHasSubmitted(true);

      // Re-fetch top3
      await fetchTop3();

      // Check if user is in top3
      const userIsInTop3 = top3.some(
        (p) => p.name === playerName && p.time === parseFloat(elapsedTime.toFixed(1))
      );
      setUserInTop3(userIsInTop3);

    } catch (err) {
      console.error('Error adding doc to leaderboard:', err);
      showTempNotification('errorSubmitting');
    }
  };

  const handleChallenge = async () => {
    const shareLink = `${window.location.origin}?score=${elapsedTime.toFixed(1)}`;
    await navigator.clipboard.writeText(
      `My score is ${elapsedTime.toFixed(1)}s, challenge me! ${shareLink}`
    );
    showTempNotification('challengeCopied');
  };

  const handleRestart = () => {
    resetGame();
    onRestart && onRestart();
  };

  return (
    <div className="final-screen">
      <h2>{t('congratulations')}</h2>
      <p>
        {t('finalTime')}: {elapsedTime.toFixed(1)}s
      </p>

      {/* Notification popup */}
      {showNotification && (
        <div className="notification">
          {notification}
        </div>
      )}

      <div className="actions">
        <button onClick={handleRestart}>{t('restart')}</button>
        <button onClick={handleChallenge}>{t('challengeFriend')}</button>
      </div>

      <div className="leaderboard-submit">
        <p>{t('submitScore')}:</p>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder={t('enterName')}
        />
        <button onClick={handleSubmitToLeaderboard}>
          {t('submit')}
        </button>
      </div>

      <h3>{t('top3')}</h3>
      <ol>
        {top3.map((p, idx) => (
          <li key={idx} style={{ fontWeight: p.name === playerName && userInTop3 ? 'bold' : 'normal' }}>
            {p.name} - {p.time}s
          </li>
        ))}
      </ol>

      <button onClick={() => window.location.replace('/leaderboard')}>
        {t('seeFullLeaderboard')}
      </button>
    </div>
  );
};

export default FinalScreen;
