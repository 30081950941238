// src/pages/RulesPage.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const RulesPage = () => {
  const { t } = useTranslation();

  return (
    <div className="rules-page">
      <h2>{t('rulesTitle')}</h2>

      {/* Render raw HTML from the translation key 'rulesDescriptionHtml' */}
      <div
        dangerouslySetInnerHTML={{ __html: t('rulesDescriptionHtml') }}
      />

      <button onClick={() => window.location.replace('/')}>
        {t('home')}
      </button>
    </div>
  );
};

export default RulesPage;
