// src/components/Leaderboard.jsx

import React, { useEffect, useState } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs, orderBy, query, limit } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const Leaderboard = () => {
  const [players, setPlayers] = useState([]);
  const [notification, setNotification] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const q = query(
          collection(db, 'leaderboard'),
          orderBy('time', 'asc'),
          limit(50)
        );
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          results.push(doc.data());
        });
        setPlayers(results);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
        showTempNotification('errorFetchingLeaderboard');
      }
    };

    fetchLeaderboard();
  }, []);

  const showTempNotification = (msgKey) => {
    setNotification(t(msgKey));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  return (
    <div className="leaderboard-page">
      <h2>{t('leaderboardTitle')}</h2>

      {showNotification && (
        <div className="notification">
          {notification}
        </div>
      )}

      <ol>
        {players.map((player, index) => (
          <li key={index}>
            {player.name} - {player.time}s
          </li>
        ))}
      </ol>

      <div className="nav-buttons">
        <button onClick={() => window.location.replace('/')}>
          {t('home')}
        </button>
        <button onClick={() => window.location.replace('/')}>
          {t('startGame')}
        </button>
      </div>
    </div>
  );
};

export default Leaderboard;
