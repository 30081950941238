// src/components/GameType2.jsx
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// A small helper to shuffle an array randomly
function shuffleArray(array) {
  // 1) Map each element to an object with a random sort key
  // 2) Sort by that key
  // 3) Extract the original item
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
}

const GameType2 = ({ question, onCorrect, onIncorrect }) => {
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedbackColor, setFeedbackColor] = useState(null);

  // Shuffle the options whenever the question changes
  useEffect(() => {
    if (question?.options) {
      const shuffled = shuffleArray(question.options);
      setShuffledOptions(shuffled);
    }
  }, [question]);

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);

    if (optionId === question.correctId) {
      setFeedbackColor('green');
      setTimeout(() => {
        setFeedbackColor(null);
        setSelectedOption(null);
        onCorrect();
      }, 1000);
    } else {
      setFeedbackColor('red');
      setTimeout(() => {
        setFeedbackColor(null);
        setSelectedOption(null);
        onIncorrect();
      }, 1000);
    }
  };

  return (
    <div className="game-type-2">
      <h2>{question.title}</h2>
      
      <div className="main-image-container">
        <img src={question.imageSrc} alt={question.imageAlt} />
      </div>

      <div className="options-container">
        {shuffledOptions.map((option) => (
          <button
            key={option.id}
            className={classNames('option-btn', {
              'highlight-green':
                selectedOption === option.id && feedbackColor === 'green',
              'highlight-red':
                selectedOption === option.id && feedbackColor === 'red',
            })}
            onClick={() => handleOptionClick(option.id)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default GameType2;
