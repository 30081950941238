// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GameProvider } from './context/GameContext';
import HomePage from './pages/HomePage';
import RulesPage from './pages/RulesPage';
import Leaderboard from './components/Leaderboard';
import GameFlow from './components/GameFlow';
import FinalScreen from './components/FinalScreen';
import GameOverScreen from './components/GameOverScreen';
import './App.css';

function App() {
  const [gameState, setGameState] = useState('home'); 
  // 'home', 'playing', 'finished', 'gameover'

  // We'll store how many were answered vs. total when game over occurs
  const [gameOverData, setGameOverData] = useState({ answered: 0, total: 0 });

  const handleStartGame = () => {
    setGameState('playing');
  };

  const handleGameEnd = () => {
    setGameState('finished');
  };

  // gameOver is now given (answered, total) from GameFlow
  const handleGameOver = (answered, total) => {
    setGameOverData({ answered, total });
    setGameState('gameover');
  };

  const handleRestart = () => {
    setGameOverData({ answered: 0, total: 0 });
    setGameState('home');
  };

  return (
    <GameProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              gameState === 'home' ? (
                <HomePage onStart={handleStartGame} />
              ) : gameState === 'playing' ? (
                // Pass the new handleGameOver that expects (answered, total)
                <GameFlow onGameEnd={handleGameEnd} onGameOver={handleGameOver} />
              ) : gameState === 'finished' ? (
                <FinalScreen onRestart={handleRestart} />
              ) : gameState === 'gameover' ? (
                // Pass answered & total to GameOverScreen
                <GameOverScreen
                  onRestart={handleRestart}
                  answered={gameOverData.answered}
                  total={gameOverData.total}
                />
              ) : null
            }
          />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
      </Router>
    </GameProvider>
  );
}

export default App;
