// src/components/questionPools.js

// Example data for Type1 (images) and Type2 (images + labels).
// Adjust these to match your actual images, alt text, etc.
export const type1Pool = [
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Broccoli.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Honey-glazed carrots.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Broccoli.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Honey-glazed carrots.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Nutritional-yeast.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Gelatin.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Nutritional-yeast.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Gelatin.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Oreos.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Marshmallows.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Oreos.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Marshmallows.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Cashew-cheese.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Feta.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Cashew-cheese.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Feta.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Baked-potato.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Butter-roasted-potatoes.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Baked-potato.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Butter-roasted-potatoes.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Lecithin.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Carmine.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Lecithin.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Carmine.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Bread.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Gummy-bears.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Bread.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Gummy-bears.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Grilled%20zucchini.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Parmesan-crusted eggplant.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Grilled%20zucchini.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Parmesan-crusted eggplant.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Xanthan%20gum.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Casein.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Xanthan%20gum.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Casein.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Potato%20chips.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Worcestershire%20sauce.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Potato%20chips.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Worcestershire%20sauce.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Nutritional-yeast.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Parmesan.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Nutritional-yeast.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Parmesan.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Steamed%20spinach.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Creamed%20corn.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Steamed%20spinach.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Creamed%20corn.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Pasta.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Caesar%20salad%20dressing.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Pasta.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Caesar%20salad%20dressing.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Roasted%20bell%20peppers.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Bacon-wrapped%20asparagus.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Roasted%20bell%20peppers.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Bacon-wrapped%20asparagus.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Agar-agar.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Whey%20protein.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Agar-agar.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Whey%20protein.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Soy%20sauce.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Ranch%20dressing.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Soy%20sauce.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Ranch%20dressing.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Grilled%20mushrooms.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Garlic%20butter%20green%20beans.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Grilled%20mushrooms.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Garlic%20butter%20green%20beans.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Seitan.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Egg white.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Seitan.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Egg white.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Ketchup.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Mayonnaise.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Ketchup.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Mayonnaise.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Cocoa%20butter.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Lard.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Cocoa%20butter.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Lard.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Peanut%20butter.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Jelly.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Peanut%20butter.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Jelly.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Soy%20milk.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Condensed%20milk.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Soy%20milk.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Condensed%20milk.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Cereals.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Yogurt.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Cereals.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Yogurt.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Tempeh.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Fish%20sauce.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Tempeh.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Fish%20sauce.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Dark%20chocolate.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Milk%20chocolate.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Dark%20chocolate.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Milk%20chocolate.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Sauteed%20kale.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Creamed%20spinach.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Sauteed%20kale.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Creamed%20spinach.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Bagels.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Croissants.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Bagels.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Croissants.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Billie%20Eilish.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Taylor%20Swift.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Billie%20Eilish.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Taylor%20Swift.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Joaquin%20Phoenix.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Leonardo%20DiCaprio.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Joaquin%20Phoenix.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Leonardo%20DiCaprio.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Ariana%20Grande.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Selena%20Gomez.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Ariana%20Grande.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Selena%20Gomez.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Venus%20Williams.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Serena%20Williams.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Venus%20Williams.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Serena%20Williams.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Lizzo.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Adele.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Lizzo.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Adele.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Woody%20Harrelson.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Brad%20Pitt.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Woody%20Harrelson.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Brad%20Pitt.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Natalie%20Portman.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Scarlett%20Johansson.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Natalie Portman.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Scarlett%20Johansson.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Lewis%20Hamilton.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Max%20Verstappen.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Lewis%20Hamilton.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Max%20Verstappen.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Rooney%20Mara.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Emma%20Stone.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Rooney%20Mara.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Emma%20Stone.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Zac%20Efron.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Chris%20Hemsworth.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Zac%20Efron.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Chris%20Hemsworth.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Jessica%20Chastain.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Jennifer%20Lawrence.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Jessica%20Chastain.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Jennifer%20Lawrence.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Novak%20Djokovic.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Rafael%20Nadal.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Novak Djokovic.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Rafael%20Nadal.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Miley%20Cyrus.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Lady%20Gaga.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Miley%20Cyrus.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Lady%20Gaga.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Gisele%20Bundchen.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Heidi%20Klum.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Gisele Bundchen.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Heidi%20Klum.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Sadie%20Sink.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Millie%20Bobby%20Brown.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Sadie%20Sink.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Millie%20Bobby%20Brown.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Pamela%20Anderson.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Megan%20Fox', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Pamela%20Anderson.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Megan%20Fox', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Russell%20Brand.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Ricky%20Gervais.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Russell%20Brand.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Ricky%20Gervais.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Jenna%20Dewan.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Channing%20Tatum.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Jenna%20Dewan.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Channing%20Tatum.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Ellen%20Page.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Jennifer%20Garner.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Ellen%20Page.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Jennifer%20Garner.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Moby.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Alanis%20Morissette.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Moby.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Alanis%20Morissette.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Jared%20Leto.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Ryan%20Reynolds.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Jared Leto.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Ryan%20Reynolds.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Colin%20Kaepernick.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Tom%20Brady.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Colin%20Kaepernick.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Tom%20Brady.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Alicia%20Silverstone.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Jessica%20Alba.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Alicia%20Silverstone.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Jessica%20Alba.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Russell%20Simmons.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Steve-O.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Russell%20Simmons.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Steve-O.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Liam%20Hemsworth.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Chris%20Pratt.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Liam%20Hemsworth.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Chris%20Pratt.webp', alt: 'Bild 2' },
    ],
    },{
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Kyrie%20Irving.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/LeBron%20James.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Kyrie%20Irving.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/LeBron%20James.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Joaquin%20Phoenix.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Jake%20Gyllenhaal.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Joaquin%20Phoenix.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Jake%20Gyllenhaal.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Woody%20Harrelson.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Mike%20Tyson.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Woody%20Harrelson.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Mike%20Tyson.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Alec%20Baldwin.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Robert%20Downey%20Jr.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Alec%20Baldwin.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Robert%20Downey%20Jr.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Casey%20Affleck.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Ben%20Affleck.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Casey%20Affleck.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Ben%20Affleck.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Olivia%20Wilde.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Emma%20Watson.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Olivia%20Wilde.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Emma%20Watson.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Billie%20Eilish.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Zendaya.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Billie%20Eilish.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Zendaya.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Erykah%20Badu.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Common.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Erykah%20Badu.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Common.webp', alt: 'Bild 2' },
    ],
    },
    {
    correctId: 'correct1',
    
    enImages: [
        { id: 'correct1', src: '/assets/images/Sia.webp', alt: 'Image 1' },
        { id: 'incorrect1', src: '/assets/images/Pink.webp', alt: 'Image 2' },
    ],
    deImages: [
        { id: 'correct1', src: '/assets/images/Sia.webp', alt: 'Bild 1' },
        { id: 'incorrect1', src: '/assets/images/Pink.webp', alt: 'Bild 2' },
    ],
    },    
    // ... add more Type1 if you want
  ];
  
  export const type2Pool = [
    {
        "correctId": "welcomevomiting_247",
        "enImage": "/assets/images/de/r_Fitness_3552.webp",
        "deImage": "/assets/images/de/r_Fitness_2922_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "welcomevomiting_247",
                "label": "welcomevomiting_247"
            },
            {
                "id": "ArrogantlyFresh",
                "label": "ArrogantlyFresh"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            }
        ]
    },
    {
        "correctId": "heartbreakingpublicity8400",
        "enImage": "/assets/images/de/r_Cooking_9512.webp",
        "deImage": "/assets/images/de/r_Cooking_5214_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            },
            {
                "id": "ResponsiblePairing",
                "label": "ResponsiblePairing"
            }
        ]
    },
    {
        "correctId": "PolitelyNecessary",
        "enImage": "/assets/images/de/r_AskReddit_7230.webp",
        "deImage": "/assets/images/de/r_AskReddit_8759_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PolitelyNecessary",
                "label": "PolitelyNecessary"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            },
            {
                "id": "itchychasm53",
                "label": "itchychasm53"
            }
        ]
    },
    {
        "correctId": "meager_quilting",
        "enImage": "/assets/images/de/r_Music_9757.webp",
        "deImage": "/assets/images/de/r_Music_7183_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "meager_quilting",
                "label": "meager_quilting"
            },
            {
                "id": "meanbicycle",
                "label": "meanbicycle"
            },
            {
                "id": "IndolentPersona",
                "label": "IndolentPersona"
            }
        ]
    },
    {
        "correctId": "smoothhelplessness6",
        "enImage": "/assets/images/de/r_FoodPorn_1515.webp",
        "deImage": "/assets/images/de/r_FoodPorn_3963_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "smoothhelplessness6",
                "label": "smoothhelplessness6"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            }
        ]
    },
    {
        "correctId": "trulyninecoverage",
        "enImage": "/assets/images/de/r_Frugal_3723.webp",
        "deImage": "/assets/images/de/r_Frugal_8567_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "trulyninecoverage",
                "label": "trulyninecoverage"
            },
            {
                "id": "AbundantLineup",
                "label": "AbundantLineup"
            },
            {
                "id": "definiterepublic",
                "label": "definiterepublic"
            }
        ]
    },
    {
        "correctId": "freelyneatretailer",
        "enImage": "/assets/images/de/r_College_3662.webp",
        "deImage": "/assets/images/de/r_College_5348_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "freelyneatretailer",
                "label": "freelyneatretailer"
            },
            {
                "id": "throwaway27544355",
                "label": "throwaway27544355"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            }
        ]
    },
    {
        "correctId": "TheSpottedDominion",
        "enImage": "/assets/images/de/r_Parenting_4051.webp",
        "deImage": "/assets/images/de/r_Parenting_9079_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheSpottedDominion",
                "label": "TheSpottedDominion"
            },
            {
                "id": "ChemicalTourist",
                "label": "ChemicalTourist"
            },
            {
                "id": "joblesspeter463",
                "label": "joblesspeter463"
            }
        ]
    },
    {
        "correctId": "TheAccessibleArchery",
        "enImage": "/assets/images/de/r_AskWomen_9795.webp",
        "deImage": "/assets/images/de/r_AskWomen_1848_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            },
            {
                "id": "InterestingOutfield",
                "label": "InterestingOutfield"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            }
        ]
    },
    {
        "correctId": "PolitelyNecessary",
        "enImage": "/assets/images/de/r_Travel_3579.webp",
        "deImage": "/assets/images/de/r_Travel_9408_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PolitelyNecessary",
                "label": "PolitelyNecessary"
            },
            {
                "id": "UnfortunateFriendliness",
                "label": "UnfortunateFriendliness"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_DIY_7943.webp",
        "deImage": "/assets/images/de/r_DIY_5225_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "boredloathing48",
                "label": "boredloathing48"
            },
            {
                "id": "meager_quilting",
                "label": "meager_quilting"
            },
            {
                "id": "contemplative_vulnerability",
                "label": "contemplative_vulnerability"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Cooking_3406.webp",
        "deImage": "/assets/images/de/r_Cooking_8174_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "CreamyDuck",
                "label": "CreamyDuck"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_9871.webp",
        "deImage": "/assets/images/de/r_AskReddit_1943_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "DroopyPanther",
                "label": "DroopyPanther"
            },
            {
                "id": "mindlesssending060",
                "label": "mindlesssending060"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Gaming_3542.webp",
        "deImage": "/assets/images/de/r_Gaming_4450_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            },
            {
                "id": "Long-termFrigate",
                "label": "Long-termFrigate"
            },
            {
                "id": "smoothhelplessness6",
                "label": "smoothhelplessness6"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_RelationshipAdvice_7053.webp",
        "deImage": "/assets/images/de/r_RelationshipAdvice_3340_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            },
            {
                "id": "Long-termFrigate",
                "label": "Long-termFrigate"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_TIL_5208.webp",
        "deImage": "/assets/images/de/r_TIL_3776_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PolitelyNecessary",
                "label": "PolitelyNecessary"
            },
            {
                "id": "mortifiedambulance853",
                "label": "mortifiedambulance853"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Movies_1088.webp",
        "deImage": "/assets/images/de/r_Movies_9804_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "ReproachfullyPeaceful",
                "label": "ReproachfullyPeaceful"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            },
            {
                "id": "SupremeQuorum",
                "label": "SupremeQuorum"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Science_2155.webp",
        "deImage": "/assets/images/de/r_Science_2133_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            },
            {
                "id": "ChemicalTourist",
                "label": "ChemicalTourist"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_History_9898.webp",
        "deImage": "/assets/images/de/r_History_5895_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "formallitre634",
                "label": "formallitre634"
            },
            {
                "id": "ill-fated_ardor",
                "label": "ill-fated_ardor"
            },
            {
                "id": "DroopyPanther",
                "label": "DroopyPanther"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_MakeupAddiction_1647.webp",
        "deImage": "/assets/images/de/r_MakeupAddiction_3275_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            },
            {
                "id": "ReproachfullyPeaceful",
                "label": "ReproachfullyPeaceful"
            },
            {
                "id": "UnfortunateFriendliness",
                "label": "UnfortunateFriendliness"
            }
        ]
    },
    {
        "correctId": "hugeinsurer204",
        "enImage": "/assets/images/de/r_Cooking_5213.webp",
        "deImage": "/assets/images/de/r_Cooking_5386_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "hugeinsurer204",
                "label": "hugeinsurer204"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            }
        ]
    },
    {
        "correctId": "boredloathing48",
        "enImage": "/assets/images/de/r_AskReddit_5347.webp",
        "deImage": "/assets/images/de/r_AskReddit_1218_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "boredloathing48",
                "label": "boredloathing48"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            },
            {
                "id": "tubbywhiteness",
                "label": "tubbywhiteness"
            }
        ]
    },
    {
        "correctId": "sincerenoodle",
        "enImage": "/assets/images/de/r_Fitness_3591.webp",
        "deImage": "/assets/images/de/r_Fitness_7050_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            },
            {
                "id": "IndolentPersona",
                "label": "IndolentPersona"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            }
        ]
    },
    {
        "correctId": "TheUnpleasantBases",
        "enImage": "/assets/images/de/r_Music_8610.webp",
        "deImage": "/assets/images/de/r_Music_9770_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheUnpleasantBases",
                "label": "TheUnpleasantBases"
            },
            {
                "id": "UnfortunateFriendliness",
                "label": "UnfortunateFriendliness"
            },
            {
                "id": "nextseizure",
                "label": "nextseizure"
            }
        ]
    },
    {
        "correctId": "definitescrum",
        "enImage": "/assets/images/de/r_Frugal_3559.webp",
        "deImage": "/assets/images/de/r_Frugal_6888_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            },
            {
                "id": "itchychasm53",
                "label": "itchychasm53"
            }
        ]
    },
    {
        "correctId": "rusticappellation61",
        "enImage": "/assets/images/de/r_Cooking_4922.webp",
        "deImage": "/assets/images/de/r_Cooking_1332_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            },
            {
                "id": "TheUnpleasantBases",
                "label": "TheUnpleasantBases"
            },
            {
                "id": "freelyneatretailer",
                "label": "freelyneatretailer"
            }
        ]
    },
    {
        "correctId": "meanbicycle",
        "enImage": "/assets/images/de/r_AskWomen_2711.webp",
        "deImage": "/assets/images/de/r_AskWomen_9181_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "meanbicycle",
                "label": "meanbicycle"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            },
            {
                "id": "ReceptiveMiner",
                "label": "ReceptiveMiner"
            }
        ]
    },
    {
        "correctId": "nextseizure",
        "enImage": "/assets/images/de/r_Relationships_4573.webp",
        "deImage": "/assets/images/de/r_Relationships_9634_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "nextseizure",
                "label": "nextseizure"
            },
            {
                "id": "ReceptiveMiner",
                "label": "ReceptiveMiner"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            }
        ]
    },
    {
        "correctId": "DailyEnvious",
        "enImage": "/assets/images/de/r_Gaming_7169.webp",
        "deImage": "/assets/images/de/r_Gaming_3664_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            }
        ]
    },
    {
        "correctId": "FlusteredMaple",
        "enImage": "/assets/images/de/r_Travel_1292.webp",
        "deImage": "/assets/images/de/r_Travel_2171_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "FlusteredMaple",
                "label": "FlusteredMaple"
            },
            {
                "id": "CreamyDuck",
                "label": "CreamyDuck"
            },
            {
                "id": "thegrowingdedication",
                "label": "thegrowingdedication"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Cooking_5101.webp",
        "deImage": "/assets/images/de/r_Cooking_5184_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskMen_3741.webp",
        "deImage": "/assets/images/de/r_AskMen_6042_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "unsuitable_statement",
                "label": "unsuitable_statement"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            },
            {
                "id": "smoothhelplessness6",
                "label": "smoothhelplessness6"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_PersonalFinance_4349.webp",
        "deImage": "/assets/images/de/r_PersonalFinance_4446_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            },
            {
                "id": "PiercingDefinition",
                "label": "PiercingDefinition"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_MovieQuotes_1874.webp",
        "deImage": "/assets/images/de/r_MovieQuotes_4116_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            },
            {
                "id": "ReproachfullyPeaceful",
                "label": "ReproachfullyPeaceful"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_TIFU_1771.webp",
        "deImage": "/assets/images/de/r_TIFU_8254_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "substantialthoroughfare44",
                "label": "substantialthoroughfare44"
            },
            {
                "id": "definiterepublic",
                "label": "definiterepublic"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_FoodPorn_2456.webp",
        "deImage": "/assets/images/de/r_FoodPorn_1366_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            },
            {
                "id": "HuskyCommander",
                "label": "HuskyCommander"
            },
            {
                "id": "oftenludicrousgrouping",
                "label": "oftenludicrousgrouping"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_5439.webp",
        "deImage": "/assets/images/de/r_AskReddit_4292_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "CynicalWalrus",
                "label": "CynicalWalrus"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            },
            {
                "id": "ChemicalTourist",
                "label": "ChemicalTourist"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_TelepathyIsReal_1285.webp",
        "deImage": "/assets/images/de/r_TelepathyIsReal_5862_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "smoothhelplessness6",
                "label": "smoothhelplessness6"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            },
            {
                "id": "nextseizure",
                "label": "nextseizure"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_DreamsAreWeird_7968.webp",
        "deImage": "/assets/images/de/r_DreamsAreWeird_3351_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            },
            {
                "id": "nextseizure",
                "label": "nextseizure"
            },
            {
                "id": "throwaway27544355",
                "label": "throwaway27544355"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_TinfoilHats_5277.webp",
        "deImage": "/assets/images/de/r_TinfoilHats_9057_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "FlusteredMaple",
                "label": "FlusteredMaple"
            },
            {
                "id": "PolitelyNecessary",
                "label": "PolitelyNecessary"
            },
            {
                "id": "quicklypassionatedexterity",
                "label": "quicklypassionatedexterity"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Cooking_8781.webp",
        "deImage": "/assets/images/de/r_Cooking_9496_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "SomberFixing",
                "label": "SomberFixing"
            },
            {
                "id": "CynicalWalrus",
                "label": "CynicalWalrus"
            },
            {
                "id": "definiterepublic",
                "label": "definiterepublic"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_3036.webp",
        "deImage": "/assets/images/de/r_AskReddit_5094_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "AbundantLineup",
                "label": "AbundantLineup"
            },
            {
                "id": "feminine_suburb",
                "label": "feminine_suburb"
            },
            {
                "id": "hugeinsurer204",
                "label": "hugeinsurer204"
            }
        ]
    },
    {
        "correctId": "HuskyCommander",
        "enImage": "/assets/images/de/r_technology_7246.webp",
        "deImage": "/assets/images/de/r_technology_1489_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "HuskyCommander",
                "label": "HuskyCommander"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            },
            {
                "id": "DroopyPanther",
                "label": "DroopyPanther"
            }
        ]
    },
    {
        "correctId": "unsuitable_statement",
        "enImage": "/assets/images/de/r_books_3098.webp",
        "deImage": "/assets/images/de/r_books_9790_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "unsuitable_statement",
                "label": "unsuitable_statement"
            },
            {
                "id": "definiterepublic",
                "label": "definiterepublic"
            },
            {
                "id": "SupremeQuorum",
                "label": "SupremeQuorum"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_8078.webp",
        "deImage": "/assets/images/de/r_AskReddit_8626_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "kaleidoscopictrauma",
                "label": "kaleidoscopictrauma"
            },
            {
                "id": "CreamyDuck",
                "label": "CreamyDuck"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_sports_4751.webp",
        "deImage": "/assets/images/de/r_sports_4952_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            },
            {
                "id": "PiercingDefinition",
                "label": "PiercingDefinition"
            },
            {
                "id": "tubbywhiteness",
                "label": "tubbywhiteness"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_gaming_8723.webp",
        "deImage": "/assets/images/de/r_gaming_8388_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "hand_to_mouth",
                "label": "hand_to_mouth"
            },
            {
                "id": "contemplative_vulnerability",
                "label": "contemplative_vulnerability"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            }
        ]
    },
    {
        "correctId": "formallitre634",
        "enImage": "/assets/images/de/r_food_7659.webp",
        "deImage": "/assets/images/de/r_food_5603_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "formallitre634",
                "label": "formallitre634"
            },
            {
                "id": "faithfully_intrepid_atheism",
                "label": "faithfully_intrepid_atheism"
            },
            {
                "id": "hugeinsurer204",
                "label": "hugeinsurer204"
            }
        ]
    },
    {
        "correctId": "joblesspeter463",
        "enImage": "/assets/images/de/r_AskReddit_9148.webp",
        "deImage": "/assets/images/de/r_AskReddit_1735_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "joblesspeter463",
                "label": "joblesspeter463"
            },
            {
                "id": "CynicalWalrus",
                "label": "CynicalWalrus"
            },
            {
                "id": "DevilishSystem",
                "label": "DevilishSystem"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Cooking_4044.webp",
        "deImage": "/assets/images/de/r_Cooking_6387_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "SupremeQuorum",
                "label": "SupremeQuorum"
            },
            {
                "id": "PM_ME_YOUR_HEARTS",
                "label": "PM_ME_YOUR_HEARTS"
            },
            {
                "id": "felinecumin",
                "label": "felinecumin"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_9328.webp",
        "deImage": "/assets/images/de/r_AskReddit_7414_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_science_4880.webp",
        "deImage": "/assets/images/de/r_science_4196_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "mortifiedambulance853",
                "label": "mortifiedambulance853"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            },
            {
                "id": "trulyninecoverage",
                "label": "trulyninecoverage"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Travel_5731.webp",
        "deImage": "/assets/images/de/r_Travel_5031_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "writhing_immunization",
                "label": "writhing_immunization"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            },
            {
                "id": "oftenludicrousgrouping",
                "label": "oftenludicrousgrouping"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_DIY_1178.webp",
        "deImage": "/assets/images/de/r_DIY_2842_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "substantialthoroughfare44",
                "label": "substantialthoroughfare44"
            },
            {
                "id": "hugeinsurer204",
                "label": "hugeinsurer204"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_4390.webp",
        "deImage": "/assets/images/de/r_AskReddit_3082_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "nebulousbuyout4",
                "label": "nebulousbuyout4"
            },
            {
                "id": "ill-fated_ardor",
                "label": "ill-fated_ardor"
            },
            {
                "id": "thicknervousness81",
                "label": "thicknervousness81"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_music_9444.webp",
        "deImage": "/assets/images/de/r_music_7738_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            },
            {
                "id": "PiercingDefinition",
                "label": "PiercingDefinition"
            },
            {
                "id": "Long-termFrigate",
                "label": "Long-termFrigate"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_AskReddit_8560.webp",
        "deImage": "/assets/images/de/r_AskReddit_3378_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_gaming_9939.webp",
        "deImage": "/assets/images/de/r_gaming_7939_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "HystericalLogic",
                "label": "HystericalLogic"
            },
            {
                "id": "feminine_suburb",
                "label": "feminine_suburb"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_fitness_8261.webp",
        "deImage": "/assets/images/de/r_fitness_2484_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            },
            {
                "id": "SupremeQuorum",
                "label": "SupremeQuorum"
            },
            {
                "id": "sombergrouping3",
                "label": "sombergrouping3"
            }
        ]
    },
    {
        "correctId": "TheAvailableRefinery",
        "enImage": "/assets/images/de/r_Cooking_2878.webp",
        "deImage": "/assets/images/de/r_Cooking_9731_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            },
            {
                "id": "RapidlyBusy",
                "label": "RapidlyBusy"
            },
            {
                "id": "CreamyDuck",
                "label": "CreamyDuck"
            }
        ]
    },
    {
        "correctId": "meanbicycle",
        "enImage": "/assets/images/de/r_AskReddit_9013.webp",
        "deImage": "/assets/images/de/r_AskReddit_1427_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "meanbicycle",
                "label": "meanbicycle"
            },
            {
                "id": "AbundantLineup",
                "label": "AbundantLineup"
            },
            {
                "id": "crossly_rapid_bomber",
                "label": "crossly_rapid_bomber"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_technology_5905.webp",
        "deImage": "/assets/images/de/r_technology_9992_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "joblesspeter463",
                "label": "joblesspeter463"
            },
            {
                "id": "HuskyCommander",
                "label": "HuskyCommander"
            },
            {
                "id": "welcomevomiting_247",
                "label": "welcomevomiting_247"
            }
        ]
    },
    {
        "correctId": "TheUnpleasantBases",
        "enImage": "/assets/images/de/r_books_8808.webp",
        "deImage": "/assets/images/de/r_books_6264_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheUnpleasantBases",
                "label": "TheUnpleasantBases"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_Movies_9164.webp",
        "deImage": "/assets/images/de/r_Movies_6013_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheSpottedDominion",
                "label": "TheSpottedDominion"
            },
            {
                "id": "strikinglongevity",
                "label": "strikinglongevity"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            }
        ]
    },
    {
        "correctId": "substantialthoroughfare44",
        "enImage": "/assets/images/de/r_AskReddit_4183.webp",
        "deImage": "/assets/images/de/r_AskReddit_1500_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "substantialthoroughfare44",
                "label": "substantialthoroughfare44"
            },
            {
                "id": "TheEnviousSubsidy",
                "label": "TheEnviousSubsidy"
            },
            {
                "id": "SomberFixing",
                "label": "SomberFixing"
            }
        ]
    },
    {
        "correctId": "TheAvailableRefinery",
        "enImage": "/assets/images/de/r_sports_8624.webp",
        "deImage": "/assets/images/de/r_sports_9998_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            },
            {
                "id": "CynicalWalrus",
                "label": "CynicalWalrus"
            }
        ]
    },
    {
        "correctId": "Long-termFrigate",
        "enImage": "/assets/images/de/r_gaming_9874.webp",
        "deImage": "/assets/images/de/r_gaming_1443_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "Long-termFrigate",
                "label": "Long-termFrigate"
            },
            {
                "id": "oftenludicrousgrouping",
                "label": "oftenludicrousgrouping"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_food_2589.webp",
        "deImage": "/assets/images/de/r_food_5611_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PM_ME_YOUR_HEARTS",
                "label": "PM_ME_YOUR_HEARTS"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            },
            {
                "id": "UnfortunateFriendliness",
                "label": "UnfortunateFriendliness"
            }
        ]
    },
    {
        "correctId": "RapidlyBusy",
        "enImage": "/assets/images/de/r_AskReddit_5865.webp",
        "deImage": "/assets/images/de/r_AskReddit_2668_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "RapidlyBusy",
                "label": "RapidlyBusy"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            }
        ]
    },
    {
        "correctId": "DailyEnvious",
        "enImage": "/assets/images/de/r_Cooking_8726.webp",
        "deImage": "/assets/images/de/r_Cooking_3542_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            }
        ]
    },
    {
        "correctId": "formallitre634",
        "enImage": "/assets/images/de/r_AskReddit_6656.webp",
        "deImage": "/assets/images/de/r_AskReddit_1984_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "formallitre634",
                "label": "formallitre634"
            },
            {
                "id": "unwieldy_sophistication",
                "label": "unwieldy_sophistication"
            },
            {
                "id": "thegrowingdedication",
                "label": "thegrowingdedication"
            }
        ]
    },
    {
        "correctId": "sincerenoodle",
        "enImage": "/assets/images/de/r_science_8875.webp",
        "deImage": "/assets/images/de/r_science_1641_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            },
            {
                "id": "welcomevomiting_247",
                "label": "welcomevomiting_247"
            }
        ]
    },
    {
        "correctId": "RapidlyBusy",
        "enImage": "/assets/images/de/r_Travel_5284.webp",
        "deImage": "/assets/images/de/r_Travel_5047_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "RapidlyBusy",
                "label": "RapidlyBusy"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            },
            {
                "id": "InterestingOutfield",
                "label": "InterestingOutfield"
            }
        ]
    },
    {
        "correctId": "boredloathing48",
        "enImage": "/assets/images/de/r_DIY_8158.webp",
        "deImage": "/assets/images/de/r_DIY_9355_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "boredloathing48",
                "label": "boredloathing48"
            },
            {
                "id": "FlusteredMaple",
                "label": "FlusteredMaple"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            }
        ]
    },
    {
        "correctId": "strongemancipation2",
        "enImage": "/assets/images/de/r_AskReddit_2988.webp",
        "deImage": "/assets/images/de/r_AskReddit_3749_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            }
        ]
    },
    {
        "correctId": "novegans",
        "enImage": "/assets/images/de/r_music_8475.webp",
        "deImage": "/assets/images/de/r_music_5362_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "RapidlyBusy",
                "label": "RapidlyBusy"
            },
            {
                "id": "InterestingOutfield",
                "label": "InterestingOutfield"
            },
            {
                "id": "welcomevomiting_247",
                "label": "welcomevomiting_247"
            }
        ]
    },
    {
        "correctId": "foolhardy_horror",
        "enImage": "/assets/images/de/r_AskReddit_4602.webp",
        "deImage": "/assets/images/de/r_AskReddit_6454_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            },
            {
                "id": "meanbicycle",
                "label": "meanbicycle"
            }
        ]
    },
    {
        "correctId": "PM_ME_YOUR_SCOOTERS",
        "enImage": "/assets/images/de/r_gaming_6053.webp",
        "deImage": "/assets/images/de/r_gaming_4178_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            },
            {
                "id": "contemplative_vulnerability",
                "label": "contemplative_vulnerability"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            }
        ]
    },
    {
        "correctId": "anxiouslysuspiciousarchitecture",
        "enImage": "/assets/images/de/r_fitness_1675.webp",
        "deImage": "/assets/images/de/r_fitness_7815_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            },
            {
                "id": "tubbywhiteness",
                "label": "tubbywhiteness"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            }
        ]
    },
    {
        "correctId": "distinctregularity",
        "enImage": "/assets/images/de/r_Cooking_6095.webp",
        "deImage": "/assets/images/de/r_Cooking_2400_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "distinctregularity",
                "label": "distinctregularity"
            },
            {
                "id": "TheUnpleasantBases",
                "label": "TheUnpleasantBases"
            },
            {
                "id": "unwieldy_sophistication",
                "label": "unwieldy_sophistication"
            }
        ]
    },
    {
        "correctId": "mindlesssending060",
        "enImage": "/assets/images/de/r_AskReddit_5188.webp",
        "deImage": "/assets/images/de/r_AskReddit_4638_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "mindlesssending060",
                "label": "mindlesssending060"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            }
        ]
    },
    {
        "correctId": "DailyEnvious",
        "enImage": "/assets/images/de/r_technology_6053.webp",
        "deImage": "/assets/images/de/r_technology_4882_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            },
            {
                "id": "TheProfitableSeeder",
                "label": "TheProfitableSeeder"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            }
        ]
    },
    {
        "correctId": "mindlesssending060",
        "enImage": "/assets/images/de/r_books_3293.webp",
        "deImage": "/assets/images/de/r_books_6902_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "mindlesssending060",
                "label": "mindlesssending060"
            },
            {
                "id": "ReceptiveMiner",
                "label": "ReceptiveMiner"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            }
        ]
    },
    {
        "correctId": "misty_mirth",
        "enImage": "/assets/images/de/r_Movies_8463.webp",
        "deImage": "/assets/images/de/r_Movies_5043_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            },
            {
                "id": "mortifiedambulance853",
                "label": "mortifiedambulance853"
            },
            {
                "id": "formallitre634",
                "label": "formallitre634"
            }
        ]
    },
    {
        "correctId": "DailyEnvious",
        "enImage": "/assets/images/de/r_AskReddit_6807.webp",
        "deImage": "/assets/images/de/r_AskReddit_1068_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "DailyEnvious",
                "label": "DailyEnvious"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "crossly_rapid_bomber",
                "label": "crossly_rapid_bomber"
            }
        ]
    },
    {
        "correctId": "strongemancipation2",
        "enImage": "/assets/images/de/r_sports_9110.webp",
        "deImage": "/assets/images/de/r_sports_9603_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "hand_to_mouth",
                "label": "hand_to_mouth"
            },
            {
                "id": "noted_fulfillment",
                "label": "noted_fulfillment"
            }
        ]
    },
    {
        "correctId": "sincerenoodle",
        "enImage": "/assets/images/de/r_gaming_6670.webp",
        "deImage": "/assets/images/de/r_gaming_8795_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sincerenoodle",
                "label": "sincerenoodle"
            },
            {
                "id": "anxiouslysuspiciousarchitecture",
                "label": "anxiouslysuspiciousarchitecture"
            },
            {
                "id": "DevilishSystem",
                "label": "DevilishSystem"
            }
        ]
    },
    {
        "correctId": "trulyninecoverage",
        "enImage": "/assets/images/de/r_food_1447.webp",
        "deImage": "/assets/images/de/r_food_7758_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "trulyninecoverage",
                "label": "trulyninecoverage"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            },
            {
                "id": "formallitre634",
                "label": "formallitre634"
            }
        ]
    },
    {
        "correctId": "strongemancipation2",
        "enImage": "/assets/images/de/r_AskReddit_8614.webp",
        "deImage": "/assets/images/de/r_AskReddit_7808_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "throwaway27544355",
                "label": "throwaway27544355"
            },
            {
                "id": "faithfully_intrepid_atheism",
                "label": "faithfully_intrepid_atheism"
            }
        ]
    },
    {
        "correctId": "HuskyCommander",
        "enImage": "/assets/images/de/r_Cooking_2705.webp",
        "deImage": "/assets/images/de/r_Cooking_1790_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "HuskyCommander",
                "label": "HuskyCommander"
            },
            {
                "id": "joblesspeter463",
                "label": "joblesspeter463"
            },
            {
                "id": "ultimately_genuine_demonstration",
                "label": "ultimately_genuine_demonstration"
            }
        ]
    },
    {
        "correctId": "SupremeQuorum",
        "enImage": "/assets/images/de/r_AskReddit_5105.webp",
        "deImage": "/assets/images/de/r_AskReddit_2153_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "SupremeQuorum",
                "label": "SupremeQuorum"
            },
            {
                "id": "ChemicalTourist",
                "label": "ChemicalTourist"
            },
            {
                "id": "\ufeffderanged_fixing",
                "label": "\ufeffderanged_fixing"
            }
        ]
    },
    {
        "correctId": "MerrilyBlaring",
        "enImage": "/assets/images/de/r_science_1347.webp",
        "deImage": "/assets/images/de/r_science_9252_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            },
            {
                "id": "TheProfitableSeeder",
                "label": "TheProfitableSeeder"
            },
            {
                "id": "boredloathing48",
                "label": "boredloathing48"
            }
        ]
    },
    {
        "correctId": "felinecumin",
        "enImage": "/assets/images/de/r_Travel_8948.webp",
        "deImage": "/assets/images/de/r_Travel_5167_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "felinecumin",
                "label": "felinecumin"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            }
        ]
    },
    {
        "correctId": "oftenludicrousgrouping",
        "enImage": "/assets/images/de/r_DIY_7155.webp",
        "deImage": "/assets/images/de/r_DIY_3747_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "oftenludicrousgrouping",
                "label": "oftenludicrousgrouping"
            },
            {
                "id": "meanbicycle",
                "label": "meanbicycle"
            },
            {
                "id": "PlayfulThunderstorm",
                "label": "PlayfulThunderstorm"
            }
        ]
    },
    {
        "correctId": "itchychasm53",
        "enImage": "/assets/images/de/r_AskReddit_3200.webp",
        "deImage": "/assets/images/de/r_AskReddit_1095_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "itchychasm53",
                "label": "itchychasm53"
            },
            {
                "id": "superficialquantity6",
                "label": "superficialquantity6"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            }
        ]
    },
    {
        "correctId": "crossly_rapid_bomber",
        "enImage": "/assets/images/de/r_music_8351.webp",
        "deImage": "/assets/images/de/r_music_6479_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "crossly_rapid_bomber",
                "label": "crossly_rapid_bomber"
            },
            {
                "id": "PiercingDefinition",
                "label": "PiercingDefinition"
            },
            {
                "id": "IndolentPersona",
                "label": "IndolentPersona"
            }
        ]
    },
    {
        "correctId": "questionable_seating",
        "enImage": "/assets/images/de/r_AskReddit_7377.webp",
        "deImage": "/assets/images/de/r_AskReddit_6703_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "questionable_seating",
                "label": "questionable_seating"
            },
            {
                "id": "AbundantLineup",
                "label": "AbundantLineup"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            }
        ]
    },
    {
        "correctId": "noted_fulfillment",
        "enImage": "/assets/images/de/r_gaming_5717.webp",
        "deImage": "/assets/images/de/r_gaming_4492_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "noted_fulfillment",
                "label": "noted_fulfillment"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            },
            {
                "id": "IndolentPersona",
                "label": "IndolentPersona"
            }
        ]
    },
    {
        "correctId": "PettyFeedback",
        "enImage": "/assets/images/de/r_fitness_1059.webp",
        "deImage": "/assets/images/de/r_fitness_7872_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PettyFeedback",
                "label": "PettyFeedback"
            },
            {
                "id": "trulyninecoverage",
                "label": "trulyninecoverage"
            },
            {
                "id": "feminine_suburb",
                "label": "feminine_suburb"
            }
        ]
    },
    {
        "correctId": "psychedelic_scolding",
        "enImage": "/assets/images/de/r_Cooking_4923.webp",
        "deImage": "/assets/images/de/r_Cooking_8512_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            },
            {
                "id": "DevilishSystem",
                "label": "DevilishSystem"
            },
            {
                "id": "mortifiedambulance853",
                "label": "mortifiedambulance853"
            }
        ]
    },
    {
        "correctId": "PM_ME_YOUR_HEARTS",
        "enImage": "/assets/images/de/r_AskReddit_6449.webp",
        "deImage": "/assets/images/de/r_AskReddit_5055_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PM_ME_YOUR_HEARTS",
                "label": "PM_ME_YOUR_HEARTS"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "foolhardy_horror",
                "label": "foolhardy_horror"
            }
        ]
    },
    {
        "correctId": "incredible_effectiveness",
        "enImage": "/assets/images/de/r_technology_3843.webp",
        "deImage": "/assets/images/de/r_technology_1603_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "incredible_effectiveness",
                "label": "incredible_effectiveness"
            },
            {
                "id": "heartbreakingpublicity8400",
                "label": "heartbreakingpublicity8400"
            },
            {
                "id": "HystericalLogic",
                "label": "HystericalLogic"
            }
        ]
    },
    {
        "correctId": "HystericalLogic",
        "enImage": "/assets/images/de/r_books_8397.webp",
        "deImage": "/assets/images/de/r_books_9706_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "HystericalLogic",
                "label": "HystericalLogic"
            },
            {
                "id": "CreamyDuck",
                "label": "CreamyDuck"
            },
            {
                "id": "oftenludicrousgrouping",
                "label": "oftenludicrousgrouping"
            }
        ]
    },
    {
        "correctId": "strikinglongevity",
        "enImage": "/assets/images/de/r_Movies_3009.webp",
        "deImage": "/assets/images/de/r_Movies_5727_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "strikinglongevity",
                "label": "strikinglongevity"
            },
            {
                "id": "HystericalLogic",
                "label": "HystericalLogic"
            },
            {
                "id": "TheAccessibleArchery",
                "label": "TheAccessibleArchery"
            }
        ]
    },
    {
        "correctId": "definitescrum",
        "enImage": "/assets/images/de/r_AskReddit_3362.webp",
        "deImage": "/assets/images/de/r_AskReddit_8932_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            },
            {
                "id": "thicknervousness81",
                "label": "thicknervousness81"
            },
            {
                "id": "DevilishSystem",
                "label": "DevilishSystem"
            }
        ]
    },
    {
        "correctId": "squealingpreoccupation471",
        "enImage": "/assets/images/de/r_sports_5511.webp",
        "deImage": "/assets/images/de/r_sports_2863_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            },
            {
                "id": "hand_to_mouth",
                "label": "hand_to_mouth"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            }
        ]
    },
    {
        "correctId": "writhing_immunization",
        "enImage": "/assets/images/de/r_gaming_6717.webp",
        "deImage": "/assets/images/de/r_gaming_1966_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "writhing_immunization",
                "label": "writhing_immunization"
            },
            {
                "id": "strongemancipation2",
                "label": "strongemancipation2"
            },
            {
                "id": "sombergrouping3",
                "label": "sombergrouping3"
            }
        ]
    },
    {
        "correctId": "HystericalLogic",
        "enImage": "/assets/images/de/r_food_6320.webp",
        "deImage": "/assets/images/de/r_food_8066_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "HystericalLogic",
                "label": "HystericalLogic"
            },
            {
                "id": "PettyFeedback",
                "label": "PettyFeedback"
            },
            {
                "id": "misty_mirth",
                "label": "misty_mirth"
            }
        ]
    },
    {
        "correctId": "PM_ME_YOUR_SCOOTERS",
        "enImage": "/assets/images/de/r_AskReddit_7056.webp",
        "deImage": "/assets/images/de/r_AskReddit_3477_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PM_ME_YOUR_SCOOTERS",
                "label": "PM_ME_YOUR_SCOOTERS"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            },
            {
                "id": "\ufeffderanged_fixing",
                "label": "\ufeffderanged_fixing"
            }
        ]
    },
    {
        "correctId": "sweetcassette",
        "enImage": "/assets/images/de/r_Cooking_5434.webp",
        "deImage": "/assets/images/de/r_Cooking_9864_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            },
            {
                "id": "sombergrouping3",
                "label": "sombergrouping3"
            },
            {
                "id": "strikinglongevity",
                "label": "strikinglongevity"
            }
        ]
    },
    {
        "correctId": "PlayfulThunderstorm",
        "enImage": "/assets/images/de/r_AskReddit_9377.webp",
        "deImage": "/assets/images/de/r_AskReddit_3554_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "PlayfulThunderstorm",
                "label": "PlayfulThunderstorm"
            },
            {
                "id": "admired_beginner",
                "label": "admired_beginner"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            }
        ]
    },
    {
        "correctId": "unsuitable_statement",
        "enImage": "/assets/images/de/r_science_6051.webp",
        "deImage": "/assets/images/de/r_science_3575_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "unsuitable_statement",
                "label": "unsuitable_statement"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            },
            {
                "id": "TheEnviousSubsidy",
                "label": "TheEnviousSubsidy"
            }
        ]
    },
    {
        "correctId": "ill-fated_ardor",
        "enImage": "/assets/images/de/r_Travel_2954.webp",
        "deImage": "/assets/images/de/r_Travel_7815_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "ill-fated_ardor",
                "label": "ill-fated_ardor"
            },
            {
                "id": "irritatingpiles583",
                "label": "irritatingpiles583"
            },
            {
                "id": "nextseizure",
                "label": "nextseizure"
            }
        ]
    },
    {
        "correctId": "theleftresurgence",
        "enImage": "/assets/images/de/r_DIY_9242.webp",
        "deImage": "/assets/images/de/r_DIY_9349_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "theleftresurgence",
                "label": "theleftresurgence"
            },
            {
                "id": "freelyneatretailer",
                "label": "freelyneatretailer"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            }
        ]
    },
    {
        "correctId": "psychedelic_scolding",
        "enImage": "/assets/images/de/r_AskReddit_2044.webp",
        "deImage": "/assets/images/de/r_AskReddit_5881_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "psychedelic_scolding",
                "label": "psychedelic_scolding"
            },
            {
                "id": "contemplative_vulnerability",
                "label": "contemplative_vulnerability"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            }
        ]
    },
    {
        "correctId": "sweetcassette",
        "enImage": "/assets/images/de/r_music_9190.webp",
        "deImage": "/assets/images/de/r_music_3088_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "sweetcassette",
                "label": "sweetcassette"
            },
            {
                "id": "rusticappellation61",
                "label": "rusticappellation61"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            }
        ]
    },
    {
        "correctId": "trulyninecoverage",
        "enImage": "/assets/images/de/r_AskReddit_3077.webp",
        "deImage": "/assets/images/de/r_AskReddit_7687_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "trulyninecoverage",
                "label": "trulyninecoverage"
            },
            {
                "id": "squealingpreoccupation471",
                "label": "squealingpreoccupation471"
            },
            {
                "id": "TheAvailableRefinery",
                "label": "TheAvailableRefinery"
            }
        ]
    },
    {
        "correctId": "definitescrum",
        "enImage": "/assets/images/de/r_gaming_2943.webp",
        "deImage": "/assets/images/de/r_gaming_3837_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "definitescrum",
                "label": "definitescrum"
            },
            {
                "id": "boredloathing48",
                "label": "boredloathing48"
            },
            {
                "id": "faithfully_intrepid_atheism",
                "label": "faithfully_intrepid_atheism"
            }
        ]
    },
    {
        "correctId": "hugeinsurer204",
        "enImage": "/assets/images/de/r_fitness_2154.webp",
        "deImage": "/assets/images/de/r_fitness_8016_de.webp",
        "options": [
            {
                "id": "novegans",
                "label": "No Vegan"
            },
            {
                "id": "hugeinsurer204",
                "label": "hugeinsurer204"
            },
            {
                "id": "nebulousbuyout4",
                "label": "nebulousbuyout4"
            },
            {
                "id": "MerrilyBlaring",
                "label": "MerrilyBlaring"
            }
        ]
    },
    // ... add more Type2 if you want
  ];
