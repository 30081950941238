// src/components/GameFlow.jsx
import React, { useState, useEffect, useContext } from 'react';
import { GameContext } from '../context/GameContext';
import { useTranslation } from 'react-i18next';
import GameType1 from './GameType1';
import GameType2 from './GameType2';
import { type1Pool, type2Pool } from './questionPools';

function shuffleArray(array) {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
}

function pickRandom(arr) {
  if (!arr || arr.length === 0) return '';
  const idx = Math.floor(Math.random() * arr.length);
  return arr[idx];
}

const GameFlow = ({ onGameEnd, onGameOver }) => {
  const {
    difficulty,
    lives,
    setLives,
    setIsGameRunning,
    elapsedTime,
  } = useContext(GameContext);

  const { t, i18n } = useTranslation();

  // The array of final questions to show
  const [questions, setQuestions] = useState([]);
  // The current question index
  const [currentIndex, setCurrentIndex] = useState(0);
  // How many questions have been attempted (correct or incorrect)
  const [answeredCount, setAnsweredCount] = useState(0);

  // Notification logic
  const [notification, setNotification] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  // Build question sets on mount / difficulty change
  useEffect(() => {
    let numType1 = 0;
    let numType2 = 0;
    let newLives = 3;

    if (difficulty === 'easy') {
      numType1 = 10;
      numType2 = 0;
      newLives = 1;
    } else if (difficulty === 'medium') {
      numType1 = 4;
      numType2 = 2;
      newLives = 2;
    } else if (difficulty === 'hard') {
      numType1 = 0;
      numType2 = 8;
      newLives = 1;
    }

    const shuffledType1Pool = shuffleArray(type1Pool);
    const shuffledType2Pool = shuffleArray(type2Pool);

    const slice1 = shuffledType1Pool.slice(0, numType1).map((q) => {
      const images = i18n.language === 'de' ? q.deImages : q.enImages;
      return {
        ...q,
        type: 1,
        title: pickRandom(
          t('questions.type1_randomTitles', { returnObjects: true })
        ),
        options: images,
      };
    });

    const slice2 = shuffledType2Pool.slice(0, numType2).map((q) => {
      const imageSrc = i18n.language === 'de' ? q.deImage : q.enImage;
      return {
        ...q,
        type: 2,
        title: pickRandom(
          t('questions.type2_randomTitles', { returnObjects: true })
        ),
        imageSrc,
      };
    });

    const combined = [...slice1, ...slice2];
    const finalShuffled = shuffleArray(combined);

    setQuestions(finalShuffled);
    setCurrentIndex(0);
    setAnsweredCount(0); // reset attempts
    setLives(newLives);
    setIsGameRunning(true);

    return () => {
      setIsGameRunning(false);
    };
  }, [difficulty, setLives, setIsGameRunning, i18n.language, t]);

  const getRandomPhrase = (arr) => {
    if (!arr || arr.length === 0) return '';
    return arr[Math.floor(Math.random() * arr.length)];
  };

  const handleCorrect = () => {
    // The user has answered a question, increment answeredCount
    setAnsweredCount((prev) => prev + 1);

    setNotification(getRandomPhrase([t('correct1'), t('correct2')]));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);

    const nextIndex = currentIndex + 1;
    if (nextIndex < questions.length) {
      setCurrentIndex(nextIndex);
    } else {
      // All questions done => final screen
      onGameEnd && onGameEnd();
    }
  };

  const handleIncorrect = () => {
    // The user has answered a question, increment answeredCount
    setAnsweredCount((prev) => prev + 1);

    setNotification(getRandomPhrase([t('incorrect1'), t('incorrect2')]));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);

    const currentQ = questions[currentIndex];
    const currentType = currentQ.type;

    setLives((prev) => prev - 1);

    if (lives - 1 <= 0) {
      // Game Over => pass answeredCount+1 as the user just attempted this question
      setIsGameRunning(false);
      onGameOver && onGameOver(answeredCount + 1, questions.length);
      return;
    }

    // find next question of the same type
    let foundIndex = -1;
    for (let i = currentIndex + 1; i < questions.length; i++) {
      if (questions[i].type === currentType) {
        foundIndex = i;
        break;
      }
    }
    if (foundIndex === -1) {
      const nextIndex = currentIndex + 1;
      if (nextIndex < questions.length) {
        setCurrentIndex(nextIndex);
      } else {
        // no more questions => final
        onGameEnd && onGameEnd();
      }
    } else {
      setCurrentIndex(foundIndex);
    }
  };

  if (questions.length === 0) {
    return <p>Loading...</p>;
  }

  const currentQuestion = questions[currentIndex];

  return (
    <div className="game-flow">
      <button onClick={() => (window.location.href = '/')}>
        {t('exit')}
      </button>

      {showNotification && (
        <div className="notification">
          {notification}
        </div>
      )}

      {currentQuestion.type === 1 && (
        <GameType1
          question={currentQuestion}
          onCorrect={handleCorrect}
          onIncorrect={handleIncorrect}
        />
      )}
      {currentQuestion.type === 2 && (
        <GameType2
          question={currentQuestion}
          onCorrect={handleCorrect}
          onIncorrect={handleIncorrect}
        />
      )}

      <div className="stopwatch">{elapsedTime.toFixed(1)}s</div>
      <p style={{ textAlign: 'center' }}>
        {t('lives')}: {lives}
      </p>
    </div>
  );
};

export default GameFlow;
